exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-503-tsx": () => import("./../../../src/pages/503.tsx" /* webpackChunkName: "component---src-pages-503-tsx" */),
  "component---src-pages-adopters-tsx": () => import("./../../../src/pages/adopters.tsx" /* webpackChunkName: "component---src-pages-adopters-tsx" */),
  "component---src-pages-comparisons-index-tsx": () => import("./../../../src/pages/comparisons/index.tsx" /* webpackChunkName: "component---src-pages-comparisons-index-tsx" */),
  "component---src-pages-comparisons-ory-versus-auth-0-tsx": () => import("./../../../src/pages/comparisons/ory-versus-auth0.tsx" /* webpackChunkName: "component---src-pages-comparisons-ory-versus-auth-0-tsx" */),
  "component---src-pages-comparisons-ory-vs-cognito-tsx": () => import("./../../../src/pages/comparisons/ory-vs-cognito.tsx" /* webpackChunkName: "component---src-pages-comparisons-ory-vs-cognito-tsx" */),
  "component---src-pages-comparisons-ory-vs-firebase-tsx": () => import("./../../../src/pages/comparisons/ory-vs-firebase.tsx" /* webpackChunkName: "component---src-pages-comparisons-ory-vs-firebase-tsx" */),
  "component---src-pages-comparisons-ory-vs-fusionauth-tsx": () => import("./../../../src/pages/comparisons/ory-vs-fusionauth.tsx" /* webpackChunkName: "component---src-pages-comparisons-ory-vs-fusionauth-tsx" */),
  "component---src-pages-comparisons-ory-vs-keycloak-tsx": () => import("./../../../src/pages/comparisons/ory-vs-keycloak.tsx" /* webpackChunkName: "component---src-pages-comparisons-ory-vs-keycloak-tsx" */),
  "component---src-pages-comparisons-ory-vs-stytch-tsx": () => import("./../../../src/pages/comparisons/ory-vs-stytch.tsx" /* webpackChunkName: "component---src-pages-comparisons-ory-vs-stytch-tsx" */),
  "component---src-pages-hydra-tsx": () => import("./../../../src/pages/hydra.tsx" /* webpackChunkName: "component---src-pages-hydra-tsx" */),
  "component---src-pages-keto-tsx": () => import("./../../../src/pages/keto.tsx" /* webpackChunkName: "component---src-pages-keto-tsx" */),
  "component---src-pages-kratos-tsx": () => import("./../../../src/pages/kratos.tsx" /* webpackChunkName: "component---src-pages-kratos-tsx" */),
  "component---src-pages-move-to-ory-tsx": () => import("./../../../src/pages/move-to-ory.tsx" /* webpackChunkName: "component---src-pages-move-to-ory-tsx" */),
  "component---src-pages-nextjs-tsx": () => import("./../../../src/pages/nextjs.tsx" /* webpackChunkName: "component---src-pages-nextjs-tsx" */),
  "component---src-pages-oathkeeper-tsx": () => import("./../../../src/pages/oathkeeper.tsx" /* webpackChunkName: "component---src-pages-oathkeeper-tsx" */),
  "component---src-pages-open-source-tsx": () => import("./../../../src/pages/open-source.tsx" /* webpackChunkName: "component---src-pages-open-source-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-professional-services-tsx": () => import("./../../../src/pages/professional-services.tsx" /* webpackChunkName: "component---src-pages-professional-services-tsx" */),
  "component---src-pages-release-notes-tsx": () => import("./../../../src/pages/release-notes.tsx" /* webpackChunkName: "component---src-pages-release-notes-tsx" */),
  "component---src-pages-summit-2021-tsx": () => import("./../../../src/pages/summit/2021.tsx" /* webpackChunkName: "component---src-pages-summit-2021-tsx" */),
  "component---src-pages-summit-2022-tsx": () => import("./../../../src/pages/summit/2022.tsx" /* webpackChunkName: "component---src-pages-summit-2022-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/cms-page.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-release-notes-tsx": () => import("./../../../src/templates/release-notes.tsx" /* webpackChunkName: "component---src-templates-release-notes-tsx" */),
  "component---src-templates-summit-tsx": () => import("./../../../src/templates/summit.tsx" /* webpackChunkName: "component---src-templates-summit-tsx" */)
}

